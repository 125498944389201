import { AJAX_LIST, ajaxFetch } from "../../Helper/Ajax";
import { getProductFilter, getApplicationFilter, toggleFilterCheckboxes, hideFilter, getCountryFilter } from "../../Helper/InspirationFilter";
import { pagination } from "../../Helper/InspirationPagination";

(() => {
    const activeFilterWrapper = document.querySelector('.inspiration__filter--active-categories');

    const Checkboxes = document.querySelectorAll(
        ".InspirationFilterCheckbox",
    );

    const resetFilter = document.querySelector('#reset-filter');
    resetFilter?.addEventListener('click', function() {

        Checkboxes.forEach(checkbox => {
            (checkbox as HTMLInputElement ).checked = false;
        });

        RemoveFilterResetButtons();
        applyFilter();
    })

    // Select all Product, Country, and Application checkboxes
    const productCheckboxes = document.querySelectorAll('.ProductFilter');
    const applicationCheckboxes = document.querySelectorAll('.ApplicationFilter');
    const countryCheckboxes = document.querySelectorAll('.CountryFilter');

    // Attach change event listeners to each checkbox
    productCheckboxes.forEach(function(checkbox) {
        checkbox.addEventListener('change', ()=> {
            applyFilter();
            addFilterResetButton(checkbox);
        });
    });

    applicationCheckboxes.forEach(function(checkbox) {
        checkbox.addEventListener('change', ()=> {
            applyFilter();
            addFilterResetButton(checkbox);
        });
    });

    countryCheckboxes.forEach(function(checkbox) {
        checkbox.addEventListener('change', ()=> {
            applyFilter();
            addFilterResetButton(checkbox);
        });
    });

    async function applyFilter() {
        const productFilters = getProductFilter();
        const applicationFilters = getApplicationFilter();
        const countryFilters = getCountryFilter();
        let filterData: any = { productFilters: productFilters };
        let loadMoreFunction: ((e: Event) => void);
        let ajaxURL: any;

        if (countryCheckboxes.length === 0 && applicationCheckboxes.length > 0) {
            // Only application filters are selected
            ajaxURL = AJAX_LIST.SMEDIAPIM_AJAX_USAGEINSPIRATIONFILTER;
            filterData.applicationFilters = applicationFilters;
            loadMoreFunction = (e: any) => {
                pagination(AJAX_LIST.SMEDIAPIM_AJAX_USAGEINSPIRATIONLOADMORE, e, productFilters, applicationFilters);
            };
        } else if (applicationCheckboxes.length === 0 && countryCheckboxes.length > 0) {
            // Only country filters are selected
            ajaxURL = AJAX_LIST.SMEDIAPIM_AJAX_CUSTOMERREPORTFILTER;
            filterData.countryFilters = countryFilters;
            loadMoreFunction = (e: any) => {
                pagination(AJAX_LIST.SMEDIAPIM_AJAX_CUSTOMERREPORTLOADMORE, e, productFilters, countryFilters);
            };
        } else {
            console.error("Filter Checkbox unavailable.");
            return;
        }


        try {
            const response = await ajaxFetch(ajaxURL, {
                body: JSON.stringify(filterData),
            });

            if (response.ok) {
                const text = await response.text();

                // Assuming you have an element with id 'filter-results' to display the new HTML
                const targetElement = document.getElementById('filter-results');
                if (targetElement) {
                    targetElement.innerHTML = text;

                    const loadMoreButton = document.querySelector(
                        "#inspirationButton",
                    ) as HTMLButtonElement;
                    if (loadMoreButton) {
                        loadMoreButton.onclick = (e) => {
                            e.preventDefault();
                            loadMoreFunction(e);
                        };
                    }
                } else {
                    console.error("Target element not found!");
                }
            } else {
                console.error("Network response was not ok.");
            }
        } catch (error) {
            console.error("Fetch error: ", error);
        }
    }

    const productFilterForm = document.getElementById("product-filter-form");

    if (productFilterForm) {
        const filterItems = productFilterForm?.querySelectorAll('.filter__input');

        filterItems?.forEach(item => {
            item.addEventListener('change', () => {
                addFilterResetButton(item);
            });
        });
    }

    function addFilterResetButton(item: Element) {
        // Check if item is checked
        if ((item as HTMLInputElement ).checked) {
            const filterTextContent = item.parentElement?.querySelector('.filter__label')?.textContent;
            const filterName = item.getAttribute('name');
            const filterType = item.getAttribute('type');
            const filterCategory = item.closest('.inspiration__filter-wrapper')?.querySelector('.inspiration__filter--header .inspiration__filter--title')?.textContent?.trim();

            // Create Filter item and fill with content
            const filterItem = document.createElement('div');
            filterItem.classList.add('inspiration__filter--active-category');
            filterItem.innerHTML = `<span>${filterCategory}: ${filterTextContent}</span><svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/></svg>`;
            if (filterName) {
                filterItem.setAttribute('name', filterName);
            }

            // Remove filterItems of same name if filterType is radio
            if (filterType === 'radio') {
                const filterItems = document.querySelectorAll('.inspiration__filter--active-category');

                filterItems.forEach(filterItem => {
                    if (filterItem.getAttribute('name') === filterName) {
                        applyFilter();
                       filterItem.remove();
                    }
                });
            }

            // Add filter to active filters
            activeFilterWrapper?.appendChild(filterItem);

            // Add event listener to remove filter
            filterItem.addEventListener('click', () => {
                (item as HTMLInputElement ).checked = false;
                (item as HTMLInputElement ).dispatchEvent(new Event('change', { bubbles: true }));
                applyFilter();
                filterItem.remove();
            });
        }
        else {
            const filterTextContent = item.parentElement?.querySelector('.filter__label')?.textContent;
            const filterCategory = item.closest('.inspiration__filter-wrapper')?.querySelector('.inspiration__filter--header .inspiration__filter--title')?.textContent?.trim();
            const filterItems = document.querySelectorAll('.inspiration__filter--active-category');

            // Remove filter from active filters
            filterItems.forEach(filterItem => {
                if (filterItem.textContent === `${filterCategory}: ${filterTextContent}`) {
                    filterItem.remove();
                }
            });
        }
    }

    function RemoveFilterResetButtons() {
        const activeFilters = document.querySelectorAll('.inspiration__filter--active-category');
        activeFilters.forEach(filter => {
            filter.remove();
        });
    }

    toggleFilterCheckboxes();
    hideFilter();

    // get distance between searchbar and top of page


    // scroll searchbar to top of viewport on searchbar change + 100px
    const searchBar = document.getElementById('search');

    searchBar?.addEventListener('input', () => {
        window.scrollTo({
            top: searchBar?.getBoundingClientRect().top + window.scrollY - 100,
            behavior: 'smooth'
        });
    });
})();
